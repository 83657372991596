import * as React from "react";
import Layout from "../../components/Layout";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function TaxAudit() {
  /* Slider */
  // const TaxAuditSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 10,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Tax Audit`,
  //     content: `Online Advisory Service of Tax Audit With TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/tax-slider.png",
  //     alt_tag: "Best Online Advisory Service of Tax Audit in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Tax Audit`,
    buyBtnLink: `#pricing-buy`,
    price: `24999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data Without Form*/
  const TaxAuditAboutData = {
    title: `Tax Audit`,
    sub_title: `Largest Tax Audit Services in India`,
    paragraph: `The Government of India carries out a variety of audits in
      accordance with various laws, including cost audits, stock
      audits, and statutory audits conducted in accordance with
      rules of company law. Similar to that, Income Tax law has
      made "Tax Audit" mandatory. Accounting records from any
      industry are examined during a tax audit, which simplifies
      the process of calculating income for tax returns.`,
    header: `It is carried out by a chartered
      accountant as specified in Section 44AB of the Income Tax
      Act of 1961. Simply put, a tax audit is an examination of
      tax related issues.`,
    points: [],
  };

  /* Img Content Component Data */
  const AccRecIcData = {
    id: '',
    mt_div: '',
    mt_img: '',
    header: 'Section 44AD Presumptive Taxation Scheme',
    image: '/imgs/registration/dsc/dsc-img-slider.png',
    alt_tag: '44AD Presumptive Taxation Scheme',
    Paragraph: '',
    points: [
      {
        content: `Businesses whose annual turnover does not cross the limit of
          Rs 2 crore are suitable for this scheme.`,
        icon: true,
      },
      {
        content: `It is not necessary to keep books of accounts under Section 44AD.`,
        icon: true,
      },

      {
        content: `Net income is anticipated to represent 8% of gross
          revenue.Net income is estimated as 6% and 8% of gross
          receipts, respectively.`,
        icon: true,
      },
      {
        content: `Businesses whose annual turnover does not cross the limit of
          Rs 2 crore are suitable for this scheme.`,
        icon: true,
      },
      {
        content: `Gross receipts are received through a digital means of payment.`,
        icon: true,
      },
      {
        content: `You need to file ITR 4 to apply these 44AD presumptive taxation scheme`,
        icon: true,
      },
    ],
  };
  /* Service Data */
  const taxData = {
    header: `Type of Accounts Come Under Tax Audit`,
    content: `Some Accountants are come under this tax audit,there are`,
    image: '/imgs/types-tax-audit.png',
    alt_img: 'TODAYFILINGS Tax Auditing Service',
    points: [
      `Individual/Proprietorship`,
      `Undivided Hindu Family`,
      `Corporation`,
      `Partnership Business`,
      `Organization of People`,
      `Local Authority`,
    ],
  };

  /*  Content Img Component Data */
  const TaxAuditCiData = {
    id: 'role',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Section 44ADA Presumptive Taxation Scheme',
    paragraph: ``,
    points: [
      {
        content: `This scheme is suitable for professions with an annual
        gross revenue of less than Rs 50 lakhs.`,
        icon: true,
      },
      {
        content: `Section 44ADA does not require the keeping of books of
        accounts.`,
        icon: true,
      },
      {
        content: `Net income is calculated as 50% of gross revenue.Net
        income is estimated as 6% and 8% of gross receipts,
        respectively.`,
        icon: true,
      },
      {
        content: `If an assesses opts for Presumptive taxation under Section
        44ADA, he must adhere to the same audit section for the
        next five fiscal years.`,
        icon: true,
      },
      {
        content: `The Income Tax Act requires the keeping of books of
        accounts.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/iec/iec-img.png',
    alt_tag: '44ADA Presumptive Taxation Scheme',
  };
  // tabData
  const AuditTabsData = {
    id: 'features',
    heading: 'Process of Tax Audit',
    paragraph: '',
    body: [
      {
        tab_title: 'Objectives',
        tab_content: [
          {
            content_title: `Objectives of Tax Audit`,
            content_paragraph: ``,
            points: [
              `It ensures that books of accounts are kept properly and
            correctly, and that they are certified by the tax auditor.`,
              `Following the rigorous verification of books of accounts,
            the tax auditor must report any observations or
            irregularities.`,
              `Annual auditing is a time-consuming and costly exercise.
            Every qualifying assesses must undergo a tax audit. It is
            now required by the Income Tax Act. Tax audits are carried
            out in India by tax consultants (Chartered Accountants).`,
              `A tax
            audit is a review of books of accounts and records to
            ensure that they accurately indicate the taxpayer's income
            and acceptable deduction claims. The primary goal of a tax
            audit is to generate a report in accordance with the
            requirements of forms 3CA/3CB and 3CD.`,
              `An audit lends credibility to information that is made
            available to employees, customers, suppliers, investors,
            and tax authorities.`,
              `The audit assures shareholders that the statistics in the
            accounts are truthful and fair.`,
              `A tax
            audit might be financially beneficial to a company. A tax
            audit aids in the development of a company's reputation.`,
            ],
          },
        ],
        content_img: '/imgs/assited/tax-audit-objective.png',
        alt_img: 'TODAYFILINGS Tax Audit Objective',
      },
      {
        tab_title: 'Types',
        tab_content: [
          {
            content_title: 'First Type',
            content_paragraph: `It is considered the most basic of all sorts of tax
          audits. During this audit, the IRS will send you a letter
          and ask you for information on a specific area of your tax
          return.`,
            points: [],
          },
          {
            content_title: 'Second Type',
            content_paragraph: `In this type of audit, the auditor will ask numerous
          comprehensive questions and will most likely spend your
          entire day; but, if the IRS requires it, they will give
          you more time to collect and submit essential facts.`,
            points: [],
          },
          {
            content_title: 'Third Type',
            content_paragraph: `In this form of audit, the IRS pays a visit to the
          taxpayer's home or place of business. They may request
          that the taxpayer examine other things as well; they will
          not be confined to specific items.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/tax-audit-types.png',
        alt_img: 'TODAYFILINGS Tax Audit Types',
      },
      {
        tab_title: 'Turnover',
        tab_content: [
          {
            content_title: 'What is included in Tax Audit Turnover?',
            content_paragraph: ``,
            points: [
              `Duty refunds received after export sales are included in a
            fiscal year's Turnover.`,
              `Revenue generated through money lender interest or foreign
            fluctuation income earned by an exporter is considered a
            part of turnover in a fiscal year, as is advance received
            and forfeited from clients, and if excise duty is included
            in turnover, it should be debited in the profit and loss
            statement.`,
            ],
          },
          {
            content_title: 'What is not covered by Turnover for Tax Audit?',
            content_paragraph: ``,
            points: [
              `Fixed Asset Purchase or Sale`,
              `Income generated by the sale of investment assets`,
              `Rental Revenue`,
              `Property, either residential or commercial`,
              `As receipts, interest income and cost reimbursement`,
            ],
          },
        ],
        content_img: '/imgs/assited/tax-audit-turnover.png',
        alt_img: 'TODAYFILINGS Tax Audit Turn Over',
      },
      {
        tab_title: 'Audit Report',
        tab_content: [
          {
            content_title: 'Other Requirements of Annual Compliance',
            content_paragraph: `The tax auditor submits his report in the appropriate
          form, which could be Form 3CA or Form 3CB, in which:`,
            points: [
              `Form 3CA is used when a person in business or profession
            is already required to have his accounts audited under
            another law.`,
              `Form 3CB is used when a person in business or profession
            does not need his accounts audited under any other law.`,
            ],
          },
          {
            content_title: `Penalty for non-filing or late filing of a tax audit
          report`,
            content_paragraph: `If a taxpayer fails to get their tax audit completed, they
          will face the following penalties:`,
            points: [
              `Rs 1,50,000 is 0.5% of total sales, turnover, or gross
            receipts.`,
            ],
          },
          {
            content_title: `When and how should a tax audit report be provided?`,
            content_paragraph: `Using his login credentials, the tax auditor files his tax
          audit report online. It is critical for taxpayers to enter
          their CA's information into their login page. Once the
          auditor has uploaded the audit report, the taxpayer must
          either approve or reject it.`,
            points: [],
          },
          {
            content_title: `The deadline for a taxpayer to have his finances audited`,
            content_paragraph: `Any person/persons covered by section 44AB must have their
          accounts audited and receive the audit reports on or by
          September 30th of that year, i.e. the due date for filing
          the income tax return.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/tax-audit-report.png',
        alt_img: 'TODAYFILINGS Tax Audit Report',
      },
    ],
  };
  /* FAQ data */
  var FAQ_data = [
    {
      header: 'What is the scope of a tax audit?',
      body: [
        {
          content: `Businesses have a tax audit limit of Rs. 1 crore.`,
          icon: true,
        },
        {
          content: `The professional tax audit ceiling is Rs. 50 lakhs.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header:
        'What is the maximum number of tax audit reports that a CA can sign?',
      body: [
        {
          content: `The maximum number of tax audits that a Chartered Accountant (CA)
          can do is 60. In the case of a corporation, the tax audit limit
          applies to all partners.`,
          icon: false,
        },
      ],
    },

    // 3
    {
      header: 'Why is a tax audit necessary?',
      body: [
        {
          content: `The primary goal of a Tax Audit is to guarantee that the books of
          Accounts are maintained in accordance with the rules of the Income
          Tax Act. Tax audit also ensures that the accounts are presented
          correctly to the Assessing Officers.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'What causes a tax audit?',
      body: [
        {
          content: `The following are the reasons for a tax audit:`,
          icon: false,
        },
        {
          content: `Earning more than the average`,
          icon: true,
        },
        {
          content: `Taking disproportionate deductions in relation to income`,
          icon: true,
        },
        {
          content: `Every year, business losses are claimed.`,
          icon: true,
        },
        {
          content: `Making insignificant deductions`,
          icon: true,
        },
      ],
    },
    // 5
    {
      header:
        'What happens if you are audited and the auditor discovers a mistake?',
      body: [
        {
          content: `If there is an error in the books of accounts, the CA will usually
                repair it. If an error is made, a penalty will be assessed, which
                may result in the payment of additional tax.`,
          icon: false,
        },
      ],
    },
    // 6
    {
      header: ' What is an illustration of tax evasion?',
      body: [
        {
          content: `False tax returns and smuggling are two instances of tax evasion,
          as are phoney documents and bribery.`,
          icon: false,
        },
      ],
    },
    // 7
    {
      header: 'What if I get audited and do not have receipts?',
      body: [
        {
          content: `If you do not have a receipt, the auditor may accept any other
          paperwork, but if you do not submit it, the auditor will not
          accept the entry in the books of accounts.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='TODAYFILINGS - Tax Audit'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={TaxAuditSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={TaxAuditAboutData} />

          <Counter />

          <Service item={taxData} />

          <ImgContent item={AccRecIcData} />

          <ContentImg CISection_data={TaxAuditCiData} />
          <section style={{ marginTop: 30 }}></section>
          <GeneralTab HTabsData={AuditTabsData} />
          <Cta />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
